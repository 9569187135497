<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > 学术研究</div>
			<el-carousel :interval="5000" arrow="always">
				<el-carousel-item v-for="(item,index) in bannerList" :key="index">
					<div class="banner" @click="toBannerDetail(item,index)"><img :src="item.pic"/></div>
				</el-carousel-item>
			</el-carousel>
			<div class="wapper flex">
				<div class="left">
					<dl>
						<dt>学术研究</dt>
						<dd @click="handleChangeTab(index)" class="flex_a" :class="{'checked' : tabIndex == index}" v-for="(item,index) in classifyList" :key="index">{{item.name}} <img src="@/assets/images/jiantou_you.png"/></dd>
						<!-- <dd @click="handleChangeTab(index)" :class="{'checked' : tabIndex == index}" v-for="(item,index) in tabList" :key="index">
							<div class="dd_title">{{item.name}} <img :src="tabIndex == index ? xia : zuo"/></div>
							<ul v-if="tabIndex == index">
								<li v-for="(element,idx) in item.list" @click="handleChild(index,idx)" :key="idx" :class="{'checked' : childIndex == idx}">
									{{element.name}} <img src="@/assets/images/huijiantou_you.png"/>
								</li>
							</ul>
						</dd> -->
					</dl>
				</div>
				<div class="right">
					<div class="top">
						关键词<el-input v-model="keyword" clearable></el-input>
						<img src="@/assets/images/shaixuan.png" @click="toSearch" alt="">
					</div>
					<ul class="item">
						<li v-for="(item,index) in list" :key="index" class="flex_a">
							<img src="@/assets/images/wenjian.png" alt="">
							<div class="info">
								<p class="flex_a"><span class="line1">{{item.title}} </span></p>
								<div>{{item.intro}}</div>
							</div>
							<div class="right_btn">
								<p>{{item.gmtCreateStr}}</p>
								<el-button size="mini" type="primary" @click="findFile(item.fileUrl)">在线预览</el-button>
							</div>
						</li>
					</ul>
					<img class="noData" v-if="total == 0" src="../../assets/images/zanwujilu.png"/>
					<el-pagination
						background
						v-if="total > 0"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="pageNo"
						:page-size="pageSize"
						layout="total, prev, pager, next, jumper"
						:total="total">
					</el-pagination>
				</div>
			</div>
			
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				tabIndex: 0,
				childIndex:0,
				classifyList: [],
				academicResearchClassId: '',
				keyword: '',
				date: '',
				pageNo: 1,
				pageSize: 5,
				total: null,
				list: [],
				zuo: require('../../assets/images/huijiantou_zuo.png'),
				xia: require('../../assets/images/jiantou_xia.png'),
				bannerList: [],
				
				
			}
		},
		created() {
			
		},
		watch: {
			"$store.state.yjIndex": {
				handler(newVal,oldVal) {
					this.tabIndex = newVal - 1
					this.getClassifyList()
					
				},
				deep: true,
				immediate: true,
		

			}
		},
		mounted() {
			this.getBanner()
		},
		methods: {
			getBanner() {
				let formData = new FormData()
					formData.append('type', 3)
				this.$http({
					url: `/bannersApi/queryBannersPage`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						if(res.data[0]){
							this.bannerList = res.data
						}
					}
				});
			},
			getClassifyList() {
				this.$http({
					url: `/academicResearchClassApi/queryAllAcademicResearchClass`,
					method: "POST",
				}).then((res) => {
					if(res.state == 10200){
						this.classifyList = res.data
						this.handleChangeTab(this.tabIndex)
					}
				});
			},
			toSearch() {
				this.pageNo = 1
				this.getList()
			},
			getList() {
				let formData = new FormData()
					formData.append('page', this.pageNo)
					formData.append('limit', this.pageSize)
					formData.append('academicResearchClassId', this.academicResearchClassId)
					formData.append('title', this.keyword)
				this.$http({
					url: `/academicResearchApi/queryAcademicResearchPage`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						this.total = res.count
						this.list = res.data
					}
					
				});
			},
			handleChangeTab(index) {
				this.tabIndex = index
				this.academicResearchClassId = this.classifyList[index].id
				this.keyword = ''
				this.page = 1
				this.getList()
			},
			toBannerDetail(item,index) {
				if(item.includeContent == 1){
					this.$router.push({ path: "/yanjiu/bannerDetail",query:{index:index}});
				}
			},
			findFile(file) {
				window.open(file)
			},
			handleChild(index,idx) {
				this.childIndex = idx
				
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.getList()
			},
			handleCurrentChange(val) {
				this.pageNo = val
				this.getList()
			}
			
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		
		background: #FFF;
		.banner{
			img{
				height: 300px;
				width: 100%;
			}
		}
		.wapper{
			padding: 30px 60px;
			.left{
				width: 240px;
				font-size: 18px;
				color: #FFF;
				margin-right: 80px;
				dt{
					height: 60px;
					line-height: 60px;
					background: url('../../assets/images/dabiaoqian.png') no-repeat;
					font-size: 24px;
					font-weight: bold;
					padding-left: 30px;
				}
				dd{
					background: #595E63;
					padding: 0 30px 0 50px;
					height: 60px;
					line-height: 60px;
					cursor: pointer;
					.dd_title{
						display: flex;
						align-items: center;
						padding: 0 30px 0 50px;
						height: 60px;
						line-height: 60px;
					}
					ul{
						background: #ABABAB;
						li{
							height: 60px;
							line-height: 60px;
							padding: 0 30px 0 50px;
							border-bottom: 1px solid #666;
							color: #333;
							display: flex;
							align-items: center;
						}
						.checked{
							color: #A5343C;
							border-bottom: 1px solid #A5343C;
							background: #FFF;
						}
					}
					img{
						width: 20px;
						height: 20px;
						margin-left: auto;
					}
				}
				.checked{
					background: #353535;
				}
			}
			.right{
				flex: 1;
				min-width: 0;
				.top{
					display: flex;
					align-items: center;
					color: #595E63;
					font-size: 16px;
					.el-input{
						width: 160px;
						margin-left: 20px;
					}
					img{
						width: 38px;
						height: 38px;
						margin-left: 20px;
						cursor: pointer;
					}
				}
				.item{
					li{
						color: #ABABAB;
						cursor: pointer;
						border-bottom: 1px dashed #DCDCDC;
						padding: 30px 0;
						img{
							width: 70px;
							height: 100px;
							border-radius: 5px;
							margin-right: 20px;
						}
						.info{
							flex: 1;
							min-width: 0;
							font-size: 16px;
							p{
								margin-bottom: 10px;
								span{
									font-size: 18px;
									color: #333333;
									flex: 1;
									margin-right: 20px;
								}
								label{
									margin-left: auto;
								}
							}
							>div{
								height: 64px;
								overflow: hidden;
							}
						}
						&:hover{
							.info{
								p{
									span{
										color: #A5343C;
									}
								}
							}
						}
						.right_btn{
							>p{
								margin-bottom: 20px;
							}
						}
					}
				}

			}
		}
	}
	
	
	
}
	
</style>
